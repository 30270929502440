import { usePathname } from "next/navigation";
import { type Session } from "next-auth";
import {
  NotificationProvider,
  FEEDS_IDENTIFIER,
  APP_TYPE,
} from "notifications";
import React, { type PropsWithChildren } from "react";

import { NOVU_CONFIGURATION } from "@/config/client";
import useSubscriberHash from "@/hooks/notification/useSubscriberHash";

type NotificationProvidersProps = PropsWithChildren & {
  session?: Session | null;
};

const NotificationProviders = (props: NotificationProvidersProps) => {
  const pathname = usePathname();
  const isNotRestrictedPage = pathname?.includes("redirect");
  const { data } = useSubscriberHash({
    enabled: Boolean(props.session?.token?.accessToken && !isNotRestrictedPage),
  });

  return (
    <NotificationProvider
      novuConfiguration={NOVU_CONFIGURATION}
      subscriberId={data?.subscriberId}
      subscriberHash={data?.subscriberHash}
      appType={APP_TYPE.account}
      feeds={FEEDS_IDENTIFIER.account}
    >
      {props.children}
    </NotificationProvider>
  );
};

export default NotificationProviders;
