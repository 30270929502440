import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";

import { type ContractEsignStatus } from "@/federatedGql/graphql";

type TUseOnboardingStore = {
  setStatusEsign: (data: ContractEsignStatus) => void;
  statusEsign?: ContractEsignStatus;
  setErrorOnboarding: (data?: string) => void;
  setTitleErrorOnboarding: (data?: string) => void;
  errorOnboarding?: string;
  titleErrorOnboarding?: string;
  setWarningOnboarding: (data?: string) => void;
  warningOnboarding?: string;
  clearStore: () => void;
  clearWarningError: () => void;
};

export const useOnboardingStore = create<TUseOnboardingStore>()(
  devtools(
    persist(
      (set) => ({
        errorOnboarding: undefined,
        statusEsign: undefined,
        warningOnboarding: undefined,
        setStatusEsign: (data) =>
          set(() => ({
            statusEsign: data,
          })),
        setErrorOnboarding: (data) =>
          set(() => ({
            errorOnboarding: data,
          })),
        setTitleErrorOnboarding: (data) =>
          set(() => ({
            titleErrorOnboarding: data,
          })),
        setWarningOnboarding: (data) =>
          set(() => ({
            warningOnboarding: data,
          })),
        clearStore: () =>
          set(() => ({
            errorOnboarding: undefined,
            statusEsign: undefined,
            warningOnboarding: undefined,
          })),
        clearWarningError: () =>
          set(() => ({
            errorOnboarding: undefined,
            warningOnboarding: undefined,
          })),
      }),
      {
        name: "onboarding-data-storage",
      }
    )
  )
);
