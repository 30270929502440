type TErrorFallback = {
  error: {
    message: string;
  };
  resetErrorBoundary: () => void;
};

const ErrorFallback = ({ error, resetErrorBoundary }: TErrorFallback) => {
  return (
    <div>
      <p className="text-error600">Something went wrong:</p>
      <div className="bg-primary50 py-2 text-xs">{error?.message}</div>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
};

export default ErrorFallback;
