import { DialogAuth } from "gtp-ui";
import { type Session } from "next-auth";
import { useSession } from "next-auth/react";

import { signIn } from "@/authentication/authService";
import { PUBLIC } from "@/config/client";

type TDialogProvider = {
  session?: Session | null;
};

const DialogAuthComponent = ({ session: sessionServer }: TDialogProvider) => {
  const { data } = useSession();

  return (
    <DialogAuth
      errorSession={data?.error}
      baseUrl={PUBLIC.BASE_URL}
      signIn={signIn}
      publicPages={["/redirect"]}
      shouldRefreshPage={
        sessionServer?.token?.accessToken !==
        sessionServer?.token?.previousAccessToken
      }
    />
  );
};

export default DialogAuthComponent;
