"use client";

import * as Sentry from "@sentry/nextjs";
import { type Session } from "next-auth";
import { SessionProvider } from "next-auth/react";
import { useEffect } from "react";
import { clearSession } from "shared-utils/authentication";

import DialogAuth from "./DialogAuth";
import { usePathname } from "@/navigation";
import { useOnboardingStore } from "@/store/onboardingStore";

type TSessionProviderClient = {
  children: React.ReactNode;
  session?: Session | null;
};

export default function SessionProviderClient({
  children,
  session,
}: TSessionProviderClient) {
  clearSession();
  const pathname = usePathname();
  const { clearStore: clearStoreOnboarding } = useOnboardingStore();

  const sessionError = session?.error;
  if (sessionError === "ERROR_GET_USER" && pathname !== "/auth/error") {
    if (typeof window !== "undefined") {
      window.location.assign("/auth/error?tracking=ERROR_GET_USER");
    }
  }

  // Sentry pupose
  useEffect(() => {
    Sentry.setUser({
      id: session?.user?.id ?? "",
    });
  }, [session?.user?.id]);

  // Update session
  useEffect(() => {
    if (!session?.token?.accessToken) {
      clearStoreOnboarding();
    }

    if (window.dataLayer) {
      window.dataLayer.push({
        user_id: session?.user?.id ?? "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  if (pathname === "/redirect") {
    return <>{children}</>;
  }

  return (
    <SessionProvider>
      <DialogAuth session={session} />
      {children}
    </SessionProvider>
  );
}
