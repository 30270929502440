"use client";

import { QueryClientProvider } from "@tanstack/react-query";
import { type Session } from "next-auth";
import { SnackbarProvider } from "shared-ui";

import ErrorBoundary from "@/components/error-boundary";
import NotificationProviders from "@/components/notification-provider";
import SessionProvider from "@/components/session-provider";

import queryClient from "@/utils/queryClient";

type TClientProviders = {
  session?: Session | null;
  children: React.ReactNode;
};

const ClientProviders = ({ children, session }: TClientProviders) => {
  return (
    <SessionProvider session={session}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <NotificationProviders session={session}>
              {children}
            </NotificationProviders>
          </SnackbarProvider>
        </QueryClientProvider>
      </ErrorBoundary>
    </SessionProvider>
  );
};

export default ClientProviders;
