import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";

import ErrorFallback from "./../error-fallback";
import { isProduction } from "@/constant";

type TErrorBoundary = {
  children: React.ReactNode;
};
const ErrorBoundary = ({ children }: TErrorBoundary) => {
  if (isProduction) return children;
  return (
    <ReactErrorBoundary FallbackComponent={ErrorFallback}>
      {children}
    </ReactErrorBoundary>
  );
};

export default ErrorBoundary;
