import { createQuery } from "react-query-kit";

import { GET_SUBSCRIBER_HASH } from "./federated/queries";
import { federatedGqlClient } from "@/utils/graphqlClient";

export type TUseSubscriberHashResponse = {
  subscriberId: string;
  subscriberHash: string;
} | null;

const useSubscriberHash = createQuery<TUseSubscriberHashResponse>({
  primaryKey: "subscriberHash",
  queryFn: async () => {
    const res = await federatedGqlClient.request(GET_SUBSCRIBER_HASH);
    if (
      !!res.subscriberHash?.subscriberId &&
      !!res.subscriberHash.subscriberHash
    ) {
      return {
        subscriberId: res.subscriberHash.subscriberId,
        subscriberHash: res.subscriberHash.subscriberHash,
      };
    }
    return null;
  },
  refetchOnWindowFocus: false,
  retry: false,
});

export default useSubscriberHash;
